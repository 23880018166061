<template>
    <div class="page">
        <main class="page-container">
            <nuxt-link class="logo" :to="$paths.getPath('landingPage')">
                <img :src="logo.data" v-bind="logo" />
            </nuxt-link>

            <DSErrorPage
                v-bind="parsedError"
            />
        </main>
        <LayoutFooter
            :icons="layoutData?.icons"
            :items="layoutData?.footerLinks"
            :download="layoutData?.downloadLinks"
            :some="layoutData?.someLinks"
            :extras="layoutData?.extraLinks"
        />
    </div>
</template>
<script setup>
import { computed, useHead, useError, useNuxtApp, useI18n, onMounted, onUnmounted, useAnalytics } from '#imports';
import { storeToRefs } from 'pinia';
import { useLayoutStore } from '~dsc/stores/cms/layout';
import { usePageStore } from '~dsc/stores/cms/page';
import gtmPerLocale from '@@/configuration/gtm.json';
import { httpStatus, httpStatusText } from '~dsc/utils/http-status';

const { initGtm, gtm } = useAnalytics();

const { $i18n } = useNuxtApp();
const { layoutData, logo } = await storeToRefs(useLayoutStore());
await useLayoutStore().layoutLoad();
const { value: error } = useError();

initGtm({ id: gtmPerLocale[$i18n.locale.value] });

useHead({
    htmlAttrs: {
        class: 'error',
    },
});

onMounted(() => {
    const event = {
        event: 'Error Message',
        gtm: {
            error_code: `${parsedError.value.statusCode} ${httpStatusText(parsedError.value.statusCode)}`,
            error_status: parsedError.value.statusCode,
            error_text: parsedError.value.message,
            error_type: parsedError.value.statusCode === httpStatus.NOT_FOUND ? 'struggle' : 'exception',
        },
    };
    gtm().trackEvent(event);
});

onUnmounted(() => {
    usePageStore().present();
});

const parsedError = computed(() => {
    if (process.env.NODE_ENV === 'development') {
        console.error(error);
    }

    return {
        statusCode: error.statusCode,
        title: error.title ?? String(error.statusCode),
        message: error.message,
        stack: error.stack,
        actions: {
            back: {
                buttonText: $i18n.t('backButtonText'),
            },
            homepage: {
                buttonText: $i18n.t('homepageButtonText'),
            },
        },
    };
});
useI18n({
    useScope: 'global',
    messages: {
        'fi-FI': {
            login: {
                link: 'Kirjaudu',
            },
            'usefulLinksTitle': 'Hyödylliset linkit',
            'backButtonText': 'Takaisin edelliselle sivulle',
            'homepageButtonText': 'Siirry etusivulle',
            'reportProblemButtonLink': 'tel:0800 131 031',
            'reportProblemButtonText': 'Ilmoita ongelmasta',
            '24hHref': 'tel:0800131031',
            '24hTitle': '24h-Asiakaspalvelu',
            '24hSubtitle': '0800 131 031',
            '24hBody': 'Soita Shell- ja St1-huoltamoihin liittyvissä asioissa maksuttomaan palvelunumeroomme.',
            'feedbackHref': 'tel:010030400',
            'feedbackTitle': 'Anna palautetta tai ota yhteyttä',
            'feedbackSubtitle': '0100 304 00',
            'feedbackBody': 'Kerro meille, missä olemme onnistuneet ja kuinka voisimme vielä kehittyä - näin onnistumme jatkossa entistä paremmin.',
            'someTitle': 'Ole yhteydessä somessa!',
            'someHref': 'https://www.facebook.com/st1suomi/',
            'someSubtitle': ' ',
            'someBody': 'Kerromme HelmiSimpukan viimeisimmät kuulumiset ja vastaamme yhteydenottoihin Facebookissa ja Instagramissa.',
            additionalLinks: {
                carwash: 'Autopesulat',
            },
        },
        'en-EU': {
            login: {
                link: 'Login',
            },
            'usefulLinksTitle': 'Useful links',
            'backButtonText': 'Back to previous page',
            'homepageButtonText': 'To front page',
            'reportProblemButtonLink': 'tel:0800 131 031',
            'reportProblemButtonText': 'Report a problem',
            '24hHref': 'tel:0800131031',
            '24hTitle': '24h-Customer service',
            '24hSubtitle': '0800 131 031',
            '24hBody': 'Call our toll free customer number for issues regarding Shell and St1 stations.',
            'feedbackHref': 'tel:010030400',
            'feedbackTitle': 'Give feedback or contact us',
            'feedbackSubtitle': '0100 304 00',
            'feedbackBody': 'Tell us what went well and how we might improve so we can do better in the future.',
            'someTitle': 'Contact us on social media!',
            'someHref': 'https://www.facebook.com/st1suomi/',
            'someSubtitle': ' ',
            'someBody': '',
            additionalLinks: {
                carwash: 'Carwashes',
            },
        },
        'sv-SE': {
            login: {
                link: 'Logga in',
            },
            'usefulLinksTitle': 'Användbara länkar',
            'backButtonText': 'Till föregående sida',
            'homepageButtonText': 'Till landningssidan',
            'reportProblemButtonLink': 'tel:0800 131 031',
            'reportProblemButtonText': 'Rapportera problem',
            '24hHref': 'tel:0800131031',
            '24hTitle': '24h-kundtjänst',
            '24hSubtitle': '0800 131 031',
            '24hBody': 'Ring angående Shell- och St1-stationer till vår servicenummer.',
            'feedbackHref': 'tel:010030400',
            'feedbackTitle': 'Ge feedback eller ta kontakt',
            'feedbackSubtitle': '0100 304 00',
            'feedbackBody': 'Kerro meille, missä olemme onnistuneet ja kuinka voisimme vielä kehittyä - näin onnistumme jatkossa entistä paremmin.',
            'feedbackBody': 'Berätta hur vi lyckats eller hur vi kan förbättra.',
            'someTitle': 'Ta kontakt via sociala medier!',
            'someHref': 'https://www.facebook.com/st1suomi/',
            'someSubtitle': ' ',
            'someBody': '',
            additionalLinks: {
                carwash: 'Biltvätt',
            },
        },
    },
});
</script>
<style lang="scss">
html.error {
    body {
        background: var(--color-base-grey-100);

        .logo {
            margin: 2rem 0 0 4rem;
            display: inline-block;
            width: 6rem;

            object {
                margin: 2rem 0;
                max-height: 2.5rem;
            }
        }

        .error {
            .ds-box.ds-box--shadow {
                box-shadow: none;
                background: var(--color-base-white);
            }

            h4 {
                text-align: center;
            }

            .useful-links {
                > .link {
                    background: var(--color-base-white);
                    margin-right: 0;
                    max-width: 100%;
                    padding: 1rem;
                    margin-bottom: 0;
                }

                .icon {
                    background: var(--color-primary-500);
                    color: var(--color-base-white);
                }
            }
        }
    }
}
</style>
